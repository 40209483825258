/* eslint-disable jsx-a11y/no-autofocus */
import { forwardRef, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Debounce } from '@medifind/hooks';
import { IconSearch } from '@medifind/icons';
import { magicAutocomplete, magicFilter } from '@medifind/interface';
import { SearchSuggestion } from '@medifind/shared-basic-components';
import { Autocomplete } from '@medifind/shared-basic-components';
import { useLocationStore } from '@medifind/zustand';
import { Filter } from './Filter';

const scrollDebounce = new Debounce(300, { runAtEnd: true }).predebounce;

const MagicFilter = forwardRef(
  (
    {
      id,
      onChange,
      onAutocomplete,
      onInputChange,
      onFocus,
      onEnter,
      showHeader = true,
      initialInput,
      prepopulate,
      classes = {},
      what,
      className,
      onSuggestionsLoaded,
      onSuggestionsSet,
      label = 'Condition',
      placeholder = 'Search conditions, procedures, specialties, or doctors',
      childrenPostMenuWrapper,
      inputRef,
      smallAt,
      showWrapper = true,
      showIcon = true,
      searchFor = ['conditions', 'specialties', 'doctors'],
      activeFirstOption = true,
      fillInput,
      highlightedItem,
    },
    ref,
  ) => {
    const listRef = useRef();
    const [suggestions, setSuggestions] = useState([]);
    const [items, setItems] = useState([]);
    const page = useRef(1);
    const pageLoading = useRef(0);
    const [inputValue, setInputValue] = useState(initialInput || {});
    const location = useLocationStore();

    /**
     * CONDITION AUTOCOMPLETE
     */

    const autocomplete = (value) => {
      onInputChange && onInputChange(value);
      if (!value.trim() && !prepopulate) return;

      onAutocomplete && onAutocomplete(value);
      magicAutocomplete({ input: value, searchFor, lat: location.lat, lon: location.lon })
        .then((data) => {
          if (onSuggestionsLoaded) onSuggestionsLoaded(data);
          page.current = data.page; // Set or reset the page number
          pageLoading.current = 0;
          const tempSuggestions = Object.entries(data)
            .filter(([key, subArr]) => searchFor.includes(key))
            .reduce(
              (prev, [key, subArr], i, arr) => [
                ...prev,
                {
                  label: key === 'conditions' ? 'Conditions and Procedures' : key,
                  ...(key === 'doctors' && {
                    alwaysShow: true,
                    hint: {
                      display: 'Search by Name',
                      link: value,
                    },
                  }),
                  items: subArr.map((datum) => ({ ...datum, key })),
                },
              ],
              [],
            )
            .sort((a, b) => {
              return searchFor.indexOf(a.label) - searchFor.indexOf(b.label);
            });
          setSuggestions(tempSuggestions.filter(({ items = [], alwaysShow }) => items.length || alwaysShow));
        })
        .catch(() => {});
    };
    // Only when the location changes, update the displayed suggestions
    useEffect(() => {
      if (location && inputValue?.display) {
        autocomplete(inputValue?.display.trim());
      }
    }, [location]);

    useEffect(() => {
      onSuggestionsSet && onSuggestionsSet(suggestions);
      const removeLabels = (searchFor || []).length === 1;
      const getItems = () => (suggestions || []).map((d) => (removeLabels ? { items: d.items } : d));
      setItems(getItems());
    }, [suggestions]);

    if (prepopulate) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useEffect(() => {
        autocomplete('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
    }
    if (ref) ref.current = { setInputValue, setSuggestions };

    useEffect(() => {
      // Skip on null pages, no input, no current suggestions
      if (page.current == null || !inputValue?.display?.trim() || !suggestions) return;
      const doctors = suggestions.find((s) => s.label === 'doctors');
      // Skip when no doctors in the suggestions
      if (!doctors?.items || doctors.items.length === 0) return;
      const currentRef = listRef.current;
      const handleScroll = (e) => {
        scrollDebounce(async () => {
          // Start loading new content at a 200 pixel offset of the bottom of the screen
          if (
            currentRef &&
            currentRef.scrollTop + currentRef.getBoundingClientRect().height + 200 > currentRef.scrollHeight &&
            pageLoading.current < page.current + 1
          ) {
            pageLoading.current = page.current + 1;
            // Might have multiple calls paginating so dont use the cancel token
            return magicFilter({ input: inputValue.display, lat: location.lat, lon: location.lon, page: page.current + 1 })
              .then((data) => {
                if (onSuggestionsLoaded) onSuggestionsLoaded(data);
                const tempSuggestions = [...suggestions];
                const newDoctors = (data?.doctors || []).map((datum) => ({ ...datum, key: 'doctors' }));
                // Append the doctor results
                tempSuggestions.forEach((d) => {
                  if (d.label === 'doctors') d.items = [...d.items, ...newDoctors];
                });
                page.current = newDoctors.length > 0 ? data.page : null; // Set null page when there is no data left
                setSuggestions(tempSuggestions);
              })
              .catch(() => {});
          }
        });
      };
      if (currentRef) {
        currentRef.addEventListener('scroll', handleScroll, false);

        return () => {
          currentRef.removeEventListener('scroll', handleScroll, false);
        };
      }
    });
    return (
      <Filter
        className={className}
        classes={classes}
        label={label}
        what={what}
        noHeader={!showHeader}
        showWrapper={showWrapper}
        smallAt={smallAt}
        placeholder={inputValue ? inputValue.display : placeholder}
      >
        <Autocomplete
          id={id}
          label={label}
          labelId={'-'} // Is replaced by <Filter>
          items={items}
          hideLabel
          inputValue={inputValue?.display ? inputValue.display : ''}
          placeholder={placeholder}
          showWrapper={showWrapper}
          fillInput={fillInput}
          onChange={(value) => {
            setInputValue({ display: value });
            autocomplete(value);
            onChange(null);
          }}
          inputRef={inputRef}
          listRef={listRef}
          onSelect={(value, ref) => {
            setInputValue(value);
            onChange(value);
          }}
          onBlur={({ highlightedIndex }) => {
            const item = !highlightedIndex ? suggestions[0]['items'][0] : suggestions.map((x) => x?.items).flat()[highlightedIndex - 1];
            highlightedItem && highlightedItem(item);
          }}
          onFocus={onFocus}
          onEnter={(value, { highlightedIndex }) => {
            const item = suggestions?.find(
              (o) =>
                (o?.label === 'Conditions and Procedures' && o?.items?.length > 0) || (o?.label === 'specialties' && o?.items?.length > 0),
            );
            onEnter && onEnter(item?.items[0] || value, { highlightedIndex });
            if (suggestions == null || typeof suggestions === 'string') {
              event.nativeEvent.preventDownshiftDefault = true;
            }
          }}
          activeFirstOption={activeFirstOption}
          classes={classes}
          childrenPreInputWrapper={({ isOpen }) =>
            showIcon && (
              <div className={classNames(classes.icon)}>
                <IconSearch />
              </div>
            )
          }
          childrenSuggestion={(item) =>
            item.key === 'doctors' ? (
              <SearchSuggestion suggestion={item} />
            ) : (
              <span
                dangerouslySetInnerHTML={{
                  __html: item.highlight ?? item.display,
                }}
              ></span>
            )
          }
          childrenPostMenuWrapper={childrenPostMenuWrapper}
        />
      </Filter>
    );
  },
);
MagicFilter.displayName = 'MagicFilter';
MagicFilter.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  showHeader: PropTypes.bool,
  conditions: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
  onSuggestionsLoaded: PropTypes.func,
  onSuggestionsSet: PropTypes.func,
  onAutocomplete: PropTypes.func,
  onInputChange: PropTypes.func,
  onFocus: PropTypes.func,
  onEnter: PropTypes.func,
  highlightedItem: PropTypes.func,
  what: PropTypes.node,
  className: PropTypes.string,
  classes: PropTypes.object,
  prepopulate: PropTypes.bool,
  initialInput: PropTypes.shape({
    display: PropTypes.string.isRequired,
    value: PropTypes.number,
  }),
  childrenPostMenuWrapper: PropTypes.func,
  fullWidth: PropTypes.bool,
  fillInput: PropTypes.bool,
  inputRef: PropTypes.any,
  smallAt: PropTypes.string,
  showWrapper: PropTypes.bool,
  showIcon: PropTypes.bool,
  activeFirstOption: PropTypes.bool,
  searchFor: PropTypes.arrayOf(PropTypes.oneOf(['specialties', 'conditions', 'symptoms', 'drugs', 'doctors', 'isymptoms'])),
};

export { MagicFilter as default, MagicFilter };
